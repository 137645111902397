<template>
    <div>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                <data-card type="quota"></data-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                <data-card type="units"></data-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                <data-card type="residents"></data-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="3" xl="3" v-if="!$store.getters.isMobile">
                <data-card type="" undefinedTypeLabel="More widgets coming soon..."></data-card>
            </v-col>
        </v-row>

        <!-- List of residents with overdue quota -->
        <v-row>
            <v-col cols="12">
                    <v-card elevation="0">
                        <v-card-title>
                            Units with pending payments
                            <v-spacer></v-spacer>
                            <v-text-field v-model="filterResidents" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
                        </v-card-title>
                        <v-card-subtitle>
                            <p class="caption grey--text">All the units with at least 1 payment pending</p>
                        </v-card-subtitle>
                        <v-data-table :headers="headers" :items="delinquentResidents" :search="filterResidents">
                            <template v-slot:item.balance="{ item }">
                                {{ formatMoney( item.balance ) }}
                            </template>
                        </v-data-table>
                    </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions" style="height:100%;" :loading="!amenitiesLoaded"> <!--  transition="fade-transition" -->
                    <v-card elevation="0" min-height="400">
                        <v-card-title>
                            <!-- <v-icon>mdi-office-building</v-icon>  -->
                            Amenities
                            <!-- <v-spacer></v-spacer>
                            <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn> -->
                        </v-card-title>
                        <v-card-subtitle>
                            <p class="caption grey--text">Here you'll find all the community amenities</p>
                        </v-card-subtitle>
                        <v-list two-line>
                            <v-list-item v-for="amenity in amenities" :key="amenity.id">
                                <v-list-item-avatar v-if="amenity.gallery[0] !== null && amenity.gallery[0] !== undefined">
                                    <v-img :src="amenity.gallery[0].url.small"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content class="px-2 py-1 mb-2"> <!-- style="background-color: #ededed;border-radius:5px;" -->
                                    <v-list-item-title>{{ amenity.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ amenity.previewDescription }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click="editAmenity(amenity)"><v-icon>mdi-pencil</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions" style="height:100%;" :loading="!eventsLoaded"> <!--  transition="fade-transition" -->
                    <v-card elevation="0" height="400">
                        <v-card-title>
                            <!-- <v-icon>mdi-calendar</v-icon>  -->
                            Upcoming Events
                            <!-- <v-spacer></v-spacer>
                            <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn> -->
                        </v-card-title>
                        <v-card-subtitle>
                            <p class="caption grey--text">Stay up-to-date with the upcoming events of your community</p>
                        </v-card-subtitle>
                        <v-list three-line>
                            <v-list-item v-for="event in events" :key="event.id">
                                <v-list-item-action>
                                    <v-avatar>
                                        <div class="text-xs-center">
                                            <div class="grey--text text--darken-2 caption">{{ event.date.monthName.substring(0, 3) }}</div>
                                            <div class="grey--text text--darken-5 title">{{ event.date.day }}</div>
                                        </div>
                                    </v-avatar>
                                </v-list-item-action>
                                <v-list-item-content class="px-2 py-1 mb-2"> <!-- style="background-color: #ededed;border-radius:5px;" -->
                                    <v-list-item-title>{{ event.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ event.date.fromTime + ' - ' + event.date.toTime }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip class="ma-1" label color="success" x-small v-on="on">{{ event.attendance['going'].length }}</v-chip>
                                            </template>
                                            <span>Confirmed</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip class="ma-1" label color="warning" x-small v-on="on">{{ event.attendance['maybe'].length }}</v-chip>
                                                </template>
                                            <span>Maybe</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip class="ma-1" label color="error" x-small v-on="on">{{ event.attendance['not going'].length }}</v-chip>
                                                </template>
                                            <span>Not Going</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip class="ma-1" label x-small v-on="on">{{ event.attendance['not answered'] }}</v-chip>
                                                </template>
                                            <span>Not Answered</span>
                                        </v-tooltip>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click="editEvent(event)"><v-icon>mdi-pencil</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                <!-- <v-card elevation="0">
                    <v-card-title>
                        Todo List
                    </v-card-title>
                    <v-card-subtitle>
                        <p class="caption grey--text">Add things to do and keep track or your day</p>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-text-field v-model="todoText" placeholder="add task" rounded single-line flat filled dense class="todo-text-field" @keyup.enter="addToTodoList">
                            <template v-slot:append>
                                <v-btn color="primary" fab small depressed class="mt-0 pt-0" @click="addToTodoList">
                                <v-icon color="white">mdi-plus</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>

                        <todo-list :dense="true" :onsidebar="true" ref="todo"></todo-list>
                    </v-card-text>
                </v-card> -->
                <v-card width="100%" height="400" elevation="0">
                    <v-row class="fill-height" align="center" justify="center" no-gutters>
                        <v-col cols="12" class="text-center">
                            <v-avatar size="80">
                                <v-icon size="80" color="grey lighten-1">mdi-plus-circle-outline</v-icon>
                            </v-avatar>
                            <p class="subtitle-2 grey--text text--darken-1">More widgets coming soon...</p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <amenity ref="amenity" :dialog="true" :dialogOpen="amenitiesDialog" @cancel="amenitiesDialog = false"
                dialogTitle="New Amenity" dialogSubtitle="Add new amenity to your community" @update="updateAmenity"></amenity>
        <event ref="event" :dialog="true" dialogTitle="New Event" dialogSubtitle="Configure your event" :dialogOpen="eventDialog" 
                @cancel="eventDialog = false" @update="updateEvent"></event>
    </div>
</template>

<script>
import Amenity from '@/components/Amenity.vue';
import Event from '@/components/Event.vue';
import DataCard from '@/components/DataCard.vue';
import { API } from '@/inc/api';

export default {
    data: () => ({
        query: '',
        filterResidents: '',
        headers: [
            { text: 'Unit', value: 'unitNumber', sortable: true },
            { text: 'Unit Type', value: 'buildingType', sortable: true },
            { text: 'Late Payments', value: 'latePayments', sortable: true },
            { text: 'Balance', align: 'left', sortable: true, value: 'balance' },
        ],
        delinquentResidents: [],
        amenities: [],
        events: [],
        todoText: '',
        delinquentResidentsLoaded: false,
        amenitiesLoaded: false,
        eventsLoaded: false,

        amenitiesDialog: false,
        eventDialog: false
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            this.delinquentResidentsLoaded = false;
            this.amenitiesLoaded = false;
            this.eventsLoaded = false;

            // Load delinquent residents
            let res = await api.getDelinquentUnits();
            this.delinquentResidentsLoaded = true;

            if( !res.data.status && res.data.error ) {
                console.log('ERROR: ' + res.data.message );
            }

            this.delinquentResidents = res.data.data;
            this.delinquentResidents = res.data.data.filter((r, i) => {
                return i < 5;
            });

            // Load location amenities

            res = await api.getAmenities();
            this.amenitiesLoaded = true;

            if( !res.data.status && res.data.error ) {
                console.log('ERROR: ' + res.data.message );
                return;
            }
            
            this.amenities = res.data.data;

            // Load upcoming events
            res = await api.getEvents();
            this.eventsLoaded = true;
            if( !res.data.status && res.data.error ) {
                console.log('ERROR: ' + res.data.message );
                return;
            }
            
            let i = 0;
            res.data.data.forEach(e => {
                if( i >= 3 )
                    return;

                this.events.push(e);
                i++;
            });
        },
        editAmenity( amenity ) {
            this.$refs.amenity.load( amenity );
            this.amenitiesDialog = true;
        },
        updateAmenity( amenity ) {
            let found = this.amenities.find(r => {
                return r.id == amenity.id;
            });
            
            if( found == undefined || found == null ) 
                return; // is not an update

            found.name = amenity.name;
            found.previewDescription = amenity.previewDescription;
            found.description = amenity.description;
            found.active = amenity.active;
            found.imageSrc = amenity.imageSrc;
            found.imageLazySrc = amenity.imageLazySrc;
            found.gallery = amenity.gallery;
            found.rules = amenity.rules;

            this.mxShowMessage( 'Amenity updated', 'success' );
        },
        editEvent( event ) {
            this.$refs.event.load( event );
            this.eventDialog = true;
        },
        updateEvent( event ) {
            let found = this.events.find(e => {
                return e.id == event.id;
            });
            
            if( found == undefined || found == null ) 
                return; // is not an update


            found.name = event.name;
            found.description = event.description;
            found.where = event.where;
            found.date = event.date;
            found.goingQty = event.goingQty;
            found.maybeQty = event.maybeQty;
            found.notGoingQty = event.notGoingQty;
            found.notAnswered = event.notAnswered;

            this.mxShowMessage( 'Event updated', 'success' );
        },
        addToTodoList() {
            this.$refs.todo.addToTodoList(this.todoText);
            this.todoText = '';
        }
    },
    components: {
        Amenity, Event, DataCard
    }
}
</script>

<style scoped>

</style>